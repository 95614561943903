$(document).ready(function() {
    
});

$('#accordionExample, #accordionPage').on('show.bs.collapse', function () {
    // para cambiar el botón que está sobre la derecha
});

$('#bannerModal').modal("show");

$(document).on('click', '.modal-close', function () {
    $('#bannerModal').modal("hide");
});

// Close navbar if it is open
$(document).click(function (event) {
    var clickover = $(event.target);
    var _opened = $(".navbar-collapse").hasClass("show");
    if (_opened === true && !clickover.hasClass("navbar-toggler")) {
        $(".navbar-toggler").click();
    }
});